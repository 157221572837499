<template>
  <section class="full-height">

    
    
    <div class="columns is-centered is-gapless is-marginless">
      <div v-if="userstats" class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">
        MS: {{ userstats.userCount }} - Medicast: {{ userstats.MedicastUsers }} users
      </div>
    </div>
    
    
    <div class="columns is-centered is-gapless is-marginless is-multiline">
      <h4 class="column is-mobile mt2rem is-12" style="text-align:center">Medicast users</h4>
      <b-table
        v-if="userstats"
        class="column is-mobile is-narrow mb2rem is-6 is-offset-3"
        
        :data="userstats.MedicastOneDevice"
        :bordered="true"
        :striped="true"
        :narrowed="true"
        :hoverable="true"

        :loading="false"
        :focusable="true"

        :header-checkable="false"
        :mobile-cards="true"                
        checkbox-position="left"
        >
        
        <template >
          <b-table-column field="platform" label="platform" width="100" numeric v-slot="props" centered>  
            {{ props.row.platform }}
          </b-table-column>
          <b-table-column field="total" label="total" width="100" v-slot="props" centered>  
            {{ props.row.total }}
          </b-table-column>
        </template>               
      </b-table>
    </div>
    
    
    
    <div class="columns is-centered is-gapless is-marginless is-multiline">
      <h4 class="column is-mobile mt2rem is-12" style="text-align:center">Medicast Devices</h4>
      <b-table
        v-if="userstats"
        class="column is-mobile is-narrow mb2rem is-6 is-offset-3"
        
        :data="userstats.MedicastAllDevices"
        :bordered="true"
        :striped="true"
        :narrowed="true"
        :hoverable="true"

        :loading="false"
        :focusable="true"

        :header-checkable="false"
        :mobile-cards="true"                
        checkbox-position="left"
        >
        
        <template >
          <b-table-column field="platform" label="platform" width="100" numeric v-slot="props" centered>  
            {{ props.row.platform }}
          </b-table-column>
          <b-table-column field="total" label="total" width="100" v-slot="props" centered>  
            {{ props.row.total }}
          </b-table-column>
        </template>               
      </b-table>
    </div>
    
    
    
    <div v-if="logState.accessLevel === 10" class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <b-button class="MyButton" @click="Unlock">{{ isUnlocked ? 'Lock' : 'Unlock' }}</b-button>
      </div>
    </div>
    
    
    <div class="columns is-centered is-gapless is-marginless">
      <b-field>
          <b-switch v-show="isUnlocked" v-model="ShowAll">Show All</b-switch>
      </b-field>
      <b-table v-if="userstats"
                class="column is-mobile is-narrow mt2rem mb2rem is-6 is-offset-3"
                
                :data="userstats.UserProf"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :hoverable="true"

                :loading="false"
                :focusable="true"

                :header-checkable="false"
                :mobile-cards="true"
                :checked-rows.sync="checkedRows"
                checkbox-position="left"
                :checkable="isUnlocked"
                :row-class="(row, index) => ShowAll || checkedRows.find((item) => {
                  return item.profession_id === row.profession_id 
                }) !== undefined  ? '' : 'is-hidden'"
                
                >
                <!-- checkable -->
                <!-- :checked-rows.sync="tbl1_checkedRows" -->
                
                <template >
                  <b-table-column field="profession_id" label="profession_id" width="30" numeric v-slot="props" centered searchable sortable>  
                    {{ props.row.profession_id }}
                  </b-table-column>
                  <b-table-column field="professions_name" label="professions_name" width="150" v-slot="props" searchable sortable>  
                    {{ props.row.professions_name }}
                  </b-table-column>
                  <b-table-column field="total" label="User count" width="60" numeric v-slot="props" searchable centered sortable>  
                    {{ props.row.total }}
                  </b-table-column>
                </template>
                
                <template #bottom-left class="columns">
                    <div class="column is-mobile is-narrow mt2rem mb2rem is-6 is-offset-3">
                      <b>Total Users</b>: {{ GetSelectedUserCount() }}
                    </div>
                    <b-button v-show="Unlock && checkedRows.length > 0" @click="DownloadUsers" class="column is-mobile is-narrow mt2rem mb2rem is-6 is-offset-3">
                      Download CSV
                    </b-button>
                </template>


                <template slot="empty">
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>
                                <b-icon
                                    icon="emoticon-sad"
                                    size="is-large">
                                </b-icon>
                            </p>
                            <p>Geen UserData gevonden.</p>
                        </div>
                    </section>
                </template>
      </b-table>
    </div>
   
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';
import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { API_ROOT } from '@/config/app.js';

import { mapState } from 'vuex';

export default {
  name: "Streams", 
         
  data() {
    return {
      ShowAll             : true,
      isUnlocked          : false,
      UnlockedTimeMsg     : '',
      LockStatus : {
        unlocked: false,
        Locktime: 0,
      },
      
      pageLoading : false,
      pageLoadingTimeout : null,
      
      checkedRows : [],
      
      userstats: null,
      
      logState : {
                      isLoggedIn  : null,
                      updateTime  : null, //time last update
                      accessLevel : 0,    
                      user_id     : null,
                      email       : null,
                      name        : null, 
                    },
    }
  },
  
  computed: {
      ...mapState('authentication', ['authentication/loggedInState','loggedInState']),
  },
  watch: {
    loggedInState() {
      this.logState = this.$store.state.authentication.loggedInState;
      // console.info("LoggedInState Changed:", this.logState);
      
      if (this.logState.accessLevel === 10) {
        // console.info(`updating lock status???`);
        this.UpdateLockStatus();
      }
    },
  },
  
  async mounted(){
    this.twoFaStatus = await this.$store.dispatch('twofa/getStatus', null);   
    if (this.twoFaStatus.status !== "active") {
      this.$buefy.dialog.alert({
          title: `2FA is required`,
          message: `2FA is required`,
          onConfirm: () => {
            this.$router.push({ path: '/profile' }).catch(() => {});
          }
      })
      return;
    }
    
    this.$store.dispatch('authentication/updateLoginState', null);
    
    // this.ShowPageLoading(15000);
    this.userstats = await this.$store.dispatch('databaseactions/DB_GetUserStats'); 
    // console.info(`userstats:`, this.userstats);
    
    
    const _this = this;
    setInterval(() => {
      _this.CheckLockStatus();
    }, 1000);
  },
  
  methods: {
    
    async UpdateLockStatus() {
      if (this.logState.accessLevel !== 10)  return false;
      // console.info(`-UpdateLockStatus??`);
      
      const LockStatus = await this.$store.dispatch('databaseactions/DB_GetLockStatus');      
      // console.info(`LockStatus:`, LockStatus);
      this.SetUnlockStatus(LockStatus);
    },
    
    async Unlock() {
      const _this = this;
      console.info(`-Unlock??`);
      if (this.isUnlocked) {
        await _this.$store.dispatch('databaseactions/DB_ClearLockStatus', { });
        await _this.UpdateLockStatus();
        return;
      }
      
      this.$buefy.dialog.prompt({
          message: `2FA Code`,
          inputAttrs: {
            placeholder: '2FA Code',
            maxlength: 6
          },
          trapFocus: true,
          onConfirm: async (value) => {
            this.$buefy.toast.open(`2FA Code: ${value}`);
            let rtn = await _this.$store.dispatch('databaseactions/DB_SetLockStatus', { twoFA : value });
            console.info(`rtn:`, rtn);
            
            _this.SetUnlockStatus(rtn);
          }
      })
    },
    
    SetUnlockStatus(LockStatus){
      if (LockStatus === false || LockStatus === null) {
        this.LockStatus = {
          unlocked: false,
          Locktime: 0,
        }
        this.CheckLockStatus();
        return;
      }
      
      this.LockStatus = LockStatus;
      if (LockStatus.unlocked === true) {
        // this.GetTableStructureData();
      }
      this.CheckLockStatus();
    },
    
    
    CheckLockStatus(){
      if (this.logState.accessLevel < 10) return false;
      // console.info(`Checking lock status???`);
      
      // console.info(`_this.LockStatus:`, this.LockStatus);
      if (this.LockStatus.unlocked === false) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "not unlocked";
        return;
      } 
      
      let curDate = new Date();
      if (parseInt(this.LockStatus.Locktime) < curDate.getTime()) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "expired";
      } else {
        this.isUnlocked      = true;
        this.UnlockedTimeMsg = Calc_TimeLeftMsg(parseInt(this.LockStatus.Locktime));
      }
    },
     
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    GetSelectedUserCount() {
      let total = 0;
      for(let prof of this.checkedRows) {
        total += prof.total
      }
      return total;
    },
    
    DownloadUsers() {
      let idlist = [];
      for(let prof of this.checkedRows) {
        idlist.push(prof.profession_id)
      }
      let query = `SELECT users.email 
                    FROM profession_user 
                  LEFT JOIN users ON users.id = profession_user.user_id 
                  LEFT JOIN preferences ON preferences.user_id = users.id
                    WHERE profession_id IN( ${idlist.join(',')}  ) 
                      AND users.automatic_guest_account = 0 
                      AND users.is_active = 1 
                      AND (preferences.has_rejected_marketing is null or preferences.has_rejected_marketing != 1)
                      AND preferences.unsubscribed_at IS NULL    
                      AND users.email NOT LIKE '%@ms.com'
                    GROUP BY users.email`;
      console.info(`Query:`, query);
      
      const urlEncode = encodeURIComponent(query);
      const fileName = encodeURIComponent(`DELETE_${idlist.join('-')}__${new Date().toISOString()}.csv`);
      // console.info(`${API_ROOT}/monitor/database/DownloadCSV?query=${urlEncode}&fname=${fileName}`);
      window.open(`${API_ROOT}/monitor/database/DownloadCSV?query=${urlEncode}&fname=${fileName}`)
    },
    
  },
};
</script>

<style scoped>
  .hasError{
    color: red;
  }
  
  .is-hidden{
    display: none;
  }
  
  .clickable{
    cursor: pointer;
  }
  .clickable:hover{
    color: #AEAEAE;
  }
  
  .itemtext{
    float:left;
    display: inline;
    min-width: 80px;
    font-weight: bold;
    /* background-color: orange;; */
  }
  
  
  .HashStatusOK,
  .GitStatusOK{
    color:darkgreen;
  }
  
  .HashStatusFailed,
  .GitStatusFailed{
    color: red;
  }
                        
</style>